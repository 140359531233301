import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "C:/wamp/www/docz/sgc-documentation/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "range-scheduler"
    }}>{`Range Scheduler`}</h1>
    <h2 {...{
      "id": "admin"
    }}>{`Admin`}</h2>
    <h2 {...{
      "id": "log-in"
    }}>{`Log in`}</h2>
    <blockquote>
      <p parentName="blockquote"><a parentName="p" {...{
          "href": "http://scottsdalegunclub.com/siteadmin"
        }}>{`http://scottsdalegunclub.com/siteadmin`}</a></p>
    </blockquote>
    <h2 {...{
      "id": "scottsdale-gun-club-range-scheduler"
    }}>{`Scottsdale Gun Club Range Scheduler`}</h2>
    <p><img alt="Lane Rep Reservations Calendar" src={require("./images/rep_res_cal_01.png")} /></p>
    <h3 {...{
      "id": "clicking-on-a-hour-column-in-a-specific-lane-row-will-open-a-popup-window-to-enter-the-customers-information"
    }}>{`Clicking on a Hour Column in a specific Lane Row will open a popup window to enter the customer's information:`}</h3>
    <p><img alt="Lane Rep Res PopUp" src={require("./images/rep_res_cal_02.png")} /></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      